import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { WebsocketService } from './websocket.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, skip, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as reducers from '../reducers';
import {
  getChatSessionById,
  reportChat,
  showMetadataTitle,
} from './chats.actions';
import { Transcript } from '@app/shared/interfaces/transcript.interface';
import { Go } from '@app/router/router.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReportChatModalComponent } from './report-chat-modal.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [WebsocketService],
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() selectedChat: boolean = false;
  @Input() isClassChat = false;
  @Input() isStudentClassChat: boolean;
  @ViewChildren('chats') chats: QueryList<any>;
  @ViewChild('container') container: ElementRef;
  isLoading = false;
  chatId: string | number = 0;
  streamText = '';
  messagesCache: Transcript[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  chatInputForm: FormGroup;
  chatTranscript: Transcript[] = [];
  requestObj = {
    positive: true,
    chat_uuid: '',
    narrative: '',
  };
  thumbsupSubmitted = false;
  thumbsdownSubmitted = false;

  constructor(
    private websocketService: WebsocketService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private store: Store<reducers.State>,
    private dialog: MatDialog
  ) {
    this.chatInputForm = this.formBuilder.group({
      textInput: ['', Validators.required],
    });
  }
  sendMsg() {
    if (this.chatInputForm.valid) {
      let message = {
        sender: '',
        msg: '',
      };
      message.sender = 'Human';
      message.msg = this.chatInputForm.get('textInput').value;
      this.isLoading = true;
      this.messagesCache.push(message);
      this.websocketService.messages.next(message);

      // Reset form after submission
      this.chatInputForm.patchValue({ textInput: '' });
      this.chatInputForm.reset();
      this.chatInputForm.markAsPristine();
    }
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.thumbsdownSubmitted = false;
      this.thumbsupSubmitted = false;

      if (params.chatid === '1') {
        this.store.dispatch(new Go({ path: [`/chats/newchat`] }));
      } else {
        this.chatId = params.chatid;
        this.requestObj = Object.assign({}, this.requestObj, {
          chat_uuid: this.chatId,
        });
        if (this.chatId) {
          this.store.dispatch(getChatSessionById.init({ chatId: this.chatId }));
        }
      }
    });

    if (!this.chatId && this.chatId !== '1') {
      this.websocketService.messages
        .pipe(takeUntil(this.destroy$))
        .subscribe((msg: any) => {
          // console.log('msg', msg);
          if (msg.Text !== '') {
            this.isLoading = false;
            if (msg.Append) {
              this.streamText += msg.Text;
              this.scrollToBottom();
            } else {
              this.streamText = msg.Text;
            }
          } else if (msg.Command === 'metadata') {
            console.log('--------uuid----', msg.Command, msg.Uuid);
            const metadata: any = {
              date: null,
              summary: null,
              title: msg.Title,
              uuid: msg.Uuid,
            };
            this.requestObj = Object.assign({}, this.requestObj, {
              chat_uuid: metadata.uuid,
            });
            this.store.dispatch(showMetadataTitle(metadata));
          } else if (!msg.Text && !msg.Append && !msg.Ellipsis) {
            if (this.streamText && !this.selectedChat) {
              this.messagesCache.push({ sender: 'AI', msg: this.streamText });
              this.streamText = '';
            }
          }
        });
    }
    this.store
      .select(reducers.getChatTranscript)
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe((chatTranscript: Transcript[]) => {
        this.chatTranscript = chatTranscript;
        this.messagesCache = chatTranscript;
      });
  }

  scrollToBottom = () => {
    try {
      this.container.nativeElement.scrollTop =
        this.container.nativeElement.scrollHeight;
    } catch (err) {}
  };

  ngAfterViewInit() {
    this.scrollToBottom();
    this.chats.changes.subscribe(this.scrollToBottom);
  }

  onReport(positive: boolean) {
    this.requestObj = Object.assign({}, this.requestObj, {
      positive: positive,
    });
    this.thumbsdownSubmitted = !positive;
    this.thumbsupSubmitted = positive;

    this.store.dispatch(reportChat.init({ report: this.requestObj }));
  }

  onReportChat() {
    const dialogRef: MatDialogRef<ReportChatModalComponent> = this.dialog.open(
      ReportChatModalComponent,
      {
        width: '450px',
        minHeight: '40vh',
        maxWidth: '45vw',
        minWidth: '300px',
        panelClass: 'custom-dialog-container',
        data: {},
      }
    );

    dialogRef.afterClosed().subscribe((form: any) => {
      if (!form) {
        return;
      }

      this.requestObj = Object.assign({}, this.requestObj, {
        narrative: form.narrative,
        positive: false,
      });
      this.store.dispatch(reportChat.init({ report: this.requestObj }));
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get hasId() {
    return this.requestObj.chat_uuid;
  }
}
