import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
  getChatSessionById,
  loadAllChats,
  lookupChats,
  reportChat,
} from './chats.actions';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import { CoreService } from '@app/core/core.service';

@Injectable()
export class ChatsEffects {
  loadAllChats$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadAllChats.init),
      map((payload: any) => payload),
      switchMap((payload: any) => {
        return this.coreService
          .createHttp('LOAD_ALL_CHATS', payload.payload, {}, {})
          .pipe(
            map((res: any) => {
              return loadAllChats.success(res.body);
            }),
            catchError(() =>
              of(
                loadAllChats.failure({
                  message: 'Failed to load all Chats.',
                })
              )
            )
          );
      })
    );
  });
  reportChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportChat.init),
      map((payload: any) => payload),
      switchMap((payload: any) => {
        return this.coreService
          .createHttp('REPORT_CHAT', payload.report, {}, {})
          .pipe(
            map((res: any) => {
              return reportChat.success(res.body);
            }),
            catchError(() =>
              of(
                reportChat.failure({
                  message: 'Failed to report Chat.',
                })
              )
            )
          );
      })
    );
  });
  getChatSessionById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getChatSessionById.init),
      map((payload: any) => payload),
      switchMap((payload: any) => {
        return this.coreService
          .createHttp('GET_CHAT_SESSION', payload, {}, {})
          .pipe(
            map((res: any) => {
              return getChatSessionById.success(res.body);
            }),
            catchError(() =>
              of(
                getChatSessionById.failure({
                  message: 'Failed to load all Chats.',
                })
              )
            )
          );
      })
    );
  });
  lookupChats$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(lookupChats.init),
      debounceTime(600),
      map((payload: any) => payload),
      switchMap((payload: any) => {
        return this.coreService
          .createHttp('LOOKUP_CHATS', payload.payload, {}, {})
          .pipe(
            map((res: any) => {
              return lookupChats.success(res.body);
            }),
            catchError(() =>
              of(
                lookupChats.failure({
                  message: 'Failed to load all Chats.',
                })
              )
            )
          );
      })
    );
  });
  constructor(private actions$: Actions, private coreService: CoreService) {}
}
