import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { getCsrfToken } from './csrf-cookie';

interface ApiRequest {
  method: string;
  url: string;
  params?: HttpParams;
  payload?: any;
  options?: any;
}

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  private environments: { [key: string]: string } = {
    mock: 'https://localhost:8443/',
    // stage_alfa: 'https://alfa.techpilotlabs.com:10443/',
    // stage_alfa: 'https://nucleus.techpilotlabs.com/',
    // stage_nucleus: 'https://org3500.nucleus.techpilotlabs.com/',
    proxy: '/',
  };

  private targetEnv = 'proxy'; // mock, alfa, nucleus or proxy
  private targetLocalHost = this.environments[this.targetEnv];

  public host =
    window.location.host.split(':')[0] == 'xlocalhost'
      ? this.targetLocalHost
      : '/';
  public baseApiPath = this.host + 'api/v2';
  public endpoints: any = {
    classic: `https://platform.prtqa.securly.io/oneroster/districts/admin@securly.io/users`,
    accounts: `${this.baseApiPath}/accounts`,
    chats: `${this.baseApiPath}/chat`,
    customers: `${this.baseApiPath}securly/customers`,
    settings: `${this.baseApiPath}/policyeditor/settings`,
    policyeditor: `${this.baseApiPath}/policyeditor`,
  };

  constructor(private http: HttpClient) {}

  createApiRequest(name: string, payload: any = {}): ApiRequest {
    // Analytics.action({ api: name });
    switch (name) {
      case 'LOAD_LOGGED_IN_USER_INFO': {
        return {
          method: 'Get',
          url: this.endpoints.policyeditor + `/whoami`,
        };
      }
      case 'LOAD_ALL_CHATS': {
        return {
          method: 'Get',
          url: this.endpoints.chats + `/sessions`,
          params: payload,
        };
      }
      case 'LOAD_CONFIG_SETTINGS': {
        return {
          method: 'Get',
          url: this.endpoints.settings,
        };
      }
      case 'LOAD_POLICIES': {
        return {
          method: 'Get',
          url: `${this.endpoints.policyeditor}/policies`,
        };
      }
      case 'LOAD_POLICY_SETTINGS': {
        const policy = encodeURIComponent(payload.policy);
        return {
          method: 'Get',
          url: `${this.endpoints.settings}/${policy}`,
        };
      }
      case 'SAVE_CONFIG_SETTINGS': {
        return {
          method: 'Post',
          url: this.endpoints.settings,
          payload: payload,
        };
      }
      case 'SAVE_POLICY_SETTINGS': {
        const policy = encodeURIComponent(payload.policy);
        return {
          method: 'Post',
          url: `${this.endpoints.settings}/${policy}`,
          payload: payload.data,
        };
      }
      case 'REPORT_CHAT': {
        return {
          method: 'Post',
          url: this.endpoints.chats + '/report',
          payload: payload,
        };
      }
      case 'GET_CHAT_SESSION': {
        return {
          method: 'Get',
          url: this.endpoints.chats + `/sessions` + `/${payload.chatId}`,
        };
      }
      case 'EDIT_ACCOUNT': {
        return {
          method: 'Put',
          url: this.endpoints.customers + `/${payload.customer.customerID}`,
          payload: {
            customer: payload.customer,
          },
        };
      }
      case 'CREATE_ACCOUNT': {
        return {
          method: 'Post',
          url: this.endpoints.customers,
          payload: {
            customer: payload.customer,
          },
        };
      }
      case 'LOOKUP_CHATS': {
        return {
          method: 'Get',
          url: this.endpoints.chats + `/sessions`,
          params: payload,
        };
      }
      default: {
        return {
          method: 'Get',
          url: this.endpoints[name],
        };
      }
    }
  }

  createHttp(
    name: string,
    values: any = {},
    payload: any = {},
    params: any,
    options?: any
    // tslint:disable-next-line: ban-types
  ): Observable<Object> {
    /**
     * NgRx appends the action type to the payload, this step removes
     * the type property to avoid API conflict for unexpected parameters
     * by assigning the value to undefined
     */
    if (payload?.type) {
      payload = Object.assign({}, payload, {
        type: undefined,
      });
    }
    const apiRequest = this.createApiRequest(name, values);

    switch (apiRequest.method.toLowerCase()) {
      case 'get':
        return this._createHttpGet(apiRequest.url, apiRequest.params);
      case 'post':
        return this._createHttpPost(apiRequest.url, apiRequest.payload);
      case 'put':
        return this._createHttpPut(apiRequest.url, apiRequest.payload);
      case 'delete':
        return this._createHttpDelete(apiRequest.url, apiRequest.payload);
      case 'postmultipart':
        return this._createHttpPostWithMultiPart(
          apiRequest.url,
          apiRequest.payload
        );
      default:
        return this.http.get('');
    }
  }

  // tslint:disable-next-line: typedef
  private _createHttpGet(url: string, params = new HttpParams()) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjEiLCJ0eXAiOiJKV1QifQ.eyJyb2xlIjoiQmFja2VuZCIsImF1ZCI6ImZpbHRlciIsImV4cCI6MTY5MjE5Mjg3MiwiaXNzIjoiaHR0cHM6Ly9hY2NvdW50cy5zZWN1cmx5LmNvbSIsInN1YiI6Ik5PVElNUCJ9.K4k2MyOsYQOJw0P7sTXjgZc0EG3pDVaHgZbUMxNEfhPe5P7zLGAe5rW7eCWhX4kfApnH-L8SbLLqcLj5qFAG482adZFH1flzGd1-Gl9MQUivMz2ltHe62NKbdGbGmxZu-8O8GmcwD6DCll8S_gDX5Wllx6kmQYie02LJN1IuBQWSDBlGCkbSvyeybmBc6XhI3UV6qzlBdzo7qESFNPMcfPhFNMda8RSliZT4YoPwfjLz49g8suF86D5d3PdsLyblrwmYVxBfswEYYy_9d_8MWKUcrUwhuTU2nZTcItUQH_4Xe6oxdZ4q2Oaiy2u9eitktP8_3Kj2pLAmm1HqBe7Sjw',
    });
    // params = params.set('_csrf', this.csrfToken);
    return this.http.get(url, { observe: 'response', headers, params }).pipe(
      catchError((err) => {
        throw err;
      })
    );
  }

  /**
   * This POST sends REQUEST as plain/text
   * @param url The URL to hit
   * @param payload The Request payload
   */
  private _createHttpPost(url: string, payload: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjEiLCJ0eXAiOiJKV1QifQ.eyJyb2xlIjoiQmFja2VuZCIsImF1ZCI6ImZpbHRlciIsImV4cCI6MTY5MjE5Mjg3MiwiaXNzIjoiaHR0cHM6Ly9hY2NvdW50cy5zZWN1cmx5LmNvbSIsInN1YiI6Ik5PVElNUCJ9.K4k2MyOsYQOJw0P7sTXjgZc0EG3pDVaHgZbUMxNEfhPe5P7zLGAe5rW7eCWhX4kfApnH-L8SbLLqcLj5qFAG482adZFH1flzGd1-Gl9MQUivMz2ltHe62NKbdGbGmxZu-8O8GmcwD6DCll8S_gDX5Wllx6kmQYie02LJN1IuBQWSDBlGCkbSvyeybmBc6XhI3UV6qzlBdzo7qESFNPMcfPhFNMda8RSliZT4YoPwfjLz49g8suF86D5d3PdsLyblrwmYVxBfswEYYy_9d_8MWKUcrUwhuTU2nZTcItUQH_4Xe6oxdZ4q2Oaiy2u9eitktP8_3Kj2pLAmm1HqBe7Sjw',
    });
    return this.http
      .post(url, payload, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((err) => {
          if (url.indexOf('logout') === -1) {
            throw err;
          } else {
            return of({});
          }
        })
      );
  }

  // tslint:disable-next-line: typedef
  private _createHttpPut(url: string, payload: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjEiLCJ0eXAiOiJKV1QifQ.eyJyb2xlIjoiQmFja2VuZCIsImF1ZCI6ImZpbHRlciIsImV4cCI6MTY5MjE5Mjg3MiwiaXNzIjoiaHR0cHM6Ly9hY2NvdW50cy5zZWN1cmx5LmNvbSIsInN1YiI6Ik5PVElNUCJ9.K4k2MyOsYQOJw0P7sTXjgZc0EG3pDVaHgZbUMxNEfhPe5P7zLGAe5rW7eCWhX4kfApnH-L8SbLLqcLj5qFAG482adZFH1flzGd1-Gl9MQUivMz2ltHe62NKbdGbGmxZu-8O8GmcwD6DCll8S_gDX5Wllx6kmQYie02LJN1IuBQWSDBlGCkbSvyeybmBc6XhI3UV6qzlBdzo7qESFNPMcfPhFNMda8RSliZT4YoPwfjLz49g8suF86D5d3PdsLyblrwmYVxBfswEYYy_9d_8MWKUcrUwhuTU2nZTcItUQH_4Xe6oxdZ4q2Oaiy2u9eitktP8_3Kj2pLAmm1HqBe7Sjw',
    });
    return this.http
      .put(url, payload, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  private _createHttpDelete(url: string, payload: any) {
    return this.http
      .request('delete', url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': getCsrfToken(),
        }),
        observe: 'response',
        body: payload,
      })
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  /**
   *
   * @param url
   * @param payload
   * @returns
   */
  private _createHttpPostWithMultiPart(url: string, payload: any) {
    const headers = new HttpHeaders({
      'X-XSRF-TOKEN': getCsrfToken(),
    });
    return this.http
      .post(url, payload, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }
}
