import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { merge, Subject } from 'rxjs';
import { filter, map, skip, switchMap, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as reducers from '../app/reducers';

import { environment } from '@env/environment';
import { LoadLoggedInUserInfo } from './user-entity/user-entity.actions';
import { LoggedInUserInfo } from './user-entity/logged-in-user-info.interface';
import { FailureSnackbarComponent } from './shared/failure-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WindowRef } from './core/window.interface';
import { MatDialog } from '@angular/material/dialog';
import { SuccessSnackbarComponent } from './shared/success-snackbar.component';
import { MatIconsService } from './shared/mat-icons-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  userInfo: LoggedInUserInfo;
  browserId = Date.now();
  componentMap = {
    APP_ERROR_MESSAGE: [FailureSnackbarComponent, 'mat-snack-bar-failure'],
    SETUP_WIZARD_OUS_RESYNCED: [
      SuccessSnackbarComponent,
      'mat-snack-bar-success',
    ],
    CHANGE_PASSWORD_SUCCESS: [
      SuccessSnackbarComponent,
      'mat-snack-bar-success',
    ],
    CHANGE_PASSWORD_FAILURE: [
      FailureSnackbarComponent,
      'mat-snack-bar-failure',
    ],
    APP_POLLING_FAILURE: [FailureSnackbarComponent, 'mat-snack-bar-failure'],
  };
  constructor(
    private iconsService: MatIconsService,
    private store: Store<reducers.State>
  ) {}

  ngOnInit() {
    this.iconsService.init();
    this.store.dispatch(LoadLoggedInUserInfo.init({}));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
