import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuccessSnackbarComponent } from './success-snackbar.component';
import { LoaderComponent } from './loader/loader.component';
import { FailureSnackbarComponent } from './failure-snackbar.component';
import { AngularMaterialModule } from './angular-material.module';
import { PaginationComponent } from './components/pagination.component';
import { LoadingIndicatorComponent } from './components/loading-indicator.component';
import { ResizeColumnDirective } from './directives/resize-column.directive';
import { VaMatTableComponent } from './components/va-mat-table.component';
import { ColumnSorterService } from './directives/column-sorter.service';
import { SubNavComponent } from './components/sub-navigation.component';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { SaveAndCancelBannerComponent } from './components/save-and-cancel-banner/save-and-cancel-banner.component';
import { SpyAnchorDirective } from './directives/spy-anchor.directive';
import { SpyAreaForDirective } from './directives/spy-area-for.directive';
import { SpyScrollContainerDirective } from './directives/spy-scroll-container.directive';
import { StickyDirective } from './directives/sticky.directive';
import { DefaultFaviconDirective } from './directives/default-favicon.directive';
import { ConvertCsvPipe } from './pipes/convert-csv.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FeatureUnavailableModalComponent } from './components/feature-unavailable-modal/feature-unavailable-modal.component';
import { PasswordChangeModalComponent } from './components/password-change-modal/password-change-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConcurrentLoginModalComponent } from './components/concurrent-login-modal/concurrent-login-modal.component';
import { YesNoGeneralModalComponent } from './components/yes-no-general-modal/yes-no-general-modal.component';
import { DisableTooltipDirective } from './directives/disable-tooltip.directive';
import { ButtonToggleComponent } from './components/button-toggle/button-toggle.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    RouterModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  declarations: [
    LoaderComponent,
    SuccessSnackbarComponent,
    FailureSnackbarComponent,
    PaginationComponent,
    LoadingIndicatorComponent,
    ResizeColumnDirective,
    VaMatTableComponent,
    SubNavComponent,
    SaveAndCancelBannerComponent,
    SpyScrollContainerDirective,
    SpyAreaForDirective,
    SpyAnchorDirective,
    StickyDirective,
    DefaultFaviconDirective,
    DisableTooltipDirective,
    ConvertCsvPipe,
    FilterPipe,
    FeatureUnavailableModalComponent,
    ConcurrentLoginModalComponent,
    PasswordChangeModalComponent,
    ButtonToggleComponent,
    YesNoGeneralModalComponent,
    DateRangePickerComponent,
  ],
  exports: [
    LoaderComponent,
    SuccessSnackbarComponent,
    FailureSnackbarComponent,
    AngularMaterialModule,
    PaginationComponent,
    LoadingIndicatorComponent,
    DisableTooltipDirective,
    ResizeColumnDirective,
    VaMatTableComponent,
    SubNavComponent,
    DateRangePickerComponent,
    SaveAndCancelBannerComponent,
    SpyScrollContainerDirective,
    SpyAreaForDirective,
    SpyAnchorDirective,
    ConvertCsvPipe,
    FilterPipe,
    ButtonToggleComponent,
  ],
  providers: [ColumnSorterService],
})
export class SharedModule {}
