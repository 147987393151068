import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-yes-no-general-modal',
  templateUrl: './yes-no-general-modal.component.html',
  styleUrls: ['./yes-no-general-modal.component.scss'],
})
export class YesNoGeneralModalComponent implements OnInit {
  headerText: string = '';
  modalDescription: string = '';
  confirmText: string = '';
  cancelText: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<YesNoGeneralModalComponent>
  ) {}

  ngOnInit(): void {
    if (this.data?.headerText) {
      this.headerText = this.data.headerText;
    }
    if (this.data?.modalDescription) {
      this.modalDescription = this.data.modalDescription;
    }
    if (this.data?.confirmText) {
      this.confirmText = this.data.confirmText;
    }
    if (this.data?.cancelText) {
      this.cancelText = this.data.cancelText;
    }
  }

  confirm() {
    this.matDialogRef.close(true);
  }
  cancel() {
    this.matDialogRef.close(false);
  }
}
