import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatDialogModule } from '@angular/material/dialog';
import { AngularMaterialModule } from './shared/angular-material.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './reducers/index';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '@env/environment';
import { CoreModule } from '@core';
import { SharedModule } from './shared';
import { ShellModule } from './shell/shell.module';
import { HistoryModule } from './history/history.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterEffects } from './router/router.effects';
export const metaReducers = [storeFreeze];
import { CoreService } from './core/core.service';
import { HistoryEffects } from './history/history.effects';
import { ChatsEffects } from './chats/chats.effects';
import {
  StoreRouterConnectingModule,
  RouterStateSerializer,
  FullRouterStateSerializer,
} from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CustomRouterStateSerializer } from './router/utils';
import { NgxPaginationModule } from 'ngx-pagination';
import { UserEntityEffects } from './user-entity/user-entity.effects';
import { AppEffects } from './app.effects';
import { WindowRef } from './core/window.interface';
import { HashLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from './app-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { RedirectLogoutInterceptor } from './shared/interceptors/redirect-logout.interceptor';
import { ChatsModule } from './chats/chats.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MaterialExampleModule } from './material.module';
import { PolicyEditorModule } from './policy-editor/policy-editor-settings.module';
import { PolicyEditorEffects } from './policy-editor/policy-editor-settings.effects';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';

/** Custom options to configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 300,
  hideDelay: 100,
  touchendHideDelay: 100,
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
    }),
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    HttpClientModule,
    MatSidenavModule,
    // MatSidenavModule,
    // MatSelectModule,
    // MatButtonModule,
    MaterialExampleModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      // preloadingStrategy: PreloadAllModules
    }),
    // StoreModule.forRoot(REDUCER_TOKEN, {
    //   metaReducers,
    //   runtimeChecks: {
    //     strictStateImmutability: true,
    //     strictActionImmutability: true
    //   }
    // }),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    EffectsModule.forRoot([
      RouterEffects,
      ChatsEffects,
      HistoryEffects,
      AppEffects,
      UserEntityEffects,
      PolicyEditorEffects,
    ]),

    CoreModule,
    SharedModule,
    ShellModule,
    ChatsModule,
    HistoryModule,
    PolicyEditorModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent, ErrorPageComponent],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: Location, useClass: HashLocationStrategy },
    CoreService,
    WindowRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RedirectLogoutInterceptor,
      multi: true,
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
