<h5 *ngIf="headerText">{{ headerText }}</h5>
<p>{{ modalDescription }}</p>
<div class="btn-group">
  <button class="btn btn-primary" (click)="confirm()">
    {{ confirmText || 'Yes' }}
  </button>
  <button class="btn btn-secondary" (click)="cancel()">
    {{ cancelText || 'No' }}
  </button>
</div>
