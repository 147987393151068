import { Component, OnDestroy, OnInit } from '@angular/core';
import * as reducers from '../../reducers';
import { Store } from '@ngrx/store';
import { Subject, takeUntil, window } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  menuHidden = true;
  userId = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private store: Store<reducers.State>, private router: Router) {}

  ngOnInit() {
    this.store
      .select(reducers.getLoggedInUserInfo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((userInfo) => {
        if (userInfo) {
          this.userId = userInfo.email || userInfo.fid;
        }
      });
  }

  onLogout(e: any) {
    e.view.logout();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
