import { createReducer, on } from '@ngrx/store';
import { LoggedInUserInfo } from './logged-in-user-info.interface';
import { LoadLoggedInUserInfo } from './user-entity.actions';
import { SnackbarNotification } from '@app/shared/interfaces/snackbar-notification.interface';

export interface State {
  loggedInUserInfo: LoggedInUserInfo;
  notification: SnackbarNotification;
}

export const initialState: State = {
  notification: {
    type: '',
    data: {
      message: '',
    },
  },
  loggedInUserInfo: {
    email: '',
    fid: '',
    isAdmin: false,
    isMock: true,
    policy: '',
  },
};

// tslint:disable-next-line: typedef
export function reducer(state = initialState, action: any): State {
  let stateToReturn = state; // default

  switch (action.type) {
    case LoadLoggedInUserInfo.success.type:
      return Object.assign({}, state, {
        loggedInUserInfo: action,
      });
    default:
      stateToReturn = state;
      break;
  }
  return stateToReturn;
}

export const getLoggedInUserInfo = (state: State) => state.loggedInUserInfo;
// export const getAppErrorMessages = (state: State) => state.notification;
