import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as reducers from '../reducers';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { loadAllChats, resetMetadata } from './chats.actions';
import { skip, Subject, takeUntil } from 'rxjs';
import { Transcript } from '@app/shared/interfaces/transcript.interface';
import { Go } from '@app/router/router.actions';
@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
})
export class ChatsComponent implements OnInit {
  chats: Transcript[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('drawer') public drawer: MatSidenav;
  loadingChats = false;
  private sideNav: MatSidenav;
  chatMetadata: any = {};

  constructor(
    private store: Store<reducers.State>,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.store
      .select(reducers.getMetadata)
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe((chatMetadata: Transcript[]) => {
        this.chatMetadata = chatMetadata;
      });
    this.store.dispatch(
      loadAllChats.init({
        payload: {
          offset: 0,
          sortColumns: ['date'],
          sortAscending: false,
          limit: 50,
        },
      })
    );

    this.store
      .select(reducers.getChats)
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe((chats: any) => {
        this.loadingChats = false;
        this.chats = chats;
      });
  }

  onChangeToggle() {
    this.drawer.toggle();
  }

  startNewChat() {
    this.store.dispatch(resetMetadata());
    this.refreshChatHistoryList();
    this.store.dispatch(
      new Go({
        path: [`/chats/1`],
      })
    );
  }

  refreshChatHistoryList() {
    this.loadingChats = true;
    this.store.dispatch(
      loadAllChats.init({
        payload: {
          offset: 0,
          sortColumns: ['date'],
          sortAscending: false,
          limit: 50,
        },
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
