import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';

import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidenav/sidebar.component';
import { AppSelectorComponent } from './app-selector/app-selector.component';
import { IsAuthorizedToViewDirective } from '@app/shared/directives/is-authorized-to-view.directive';

@NgModule({
  imports: [CommonModule, NgbModule, RouterModule, MatIconModule],
  declarations: [
    HeaderComponent,
    AppSelectorComponent,
    SidebarComponent,
    ShellComponent,
    IsAuthorizedToViewDirective,
  ],
})
export class ShellModule {}
