import { ActionReducerMap, createSelector, ActionReducer } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import * as fromRouter from '@ngrx/router-store';
import { RouterStateUrl } from '../router/utils';
import * as fromApp from '../app.reducer';
import * as fromAccounts from '../history/history.reducer';
import * as fromChats from '../chats/chats.reducer';
import * as fromUserEntity from '../user-entity/user-entity.reducer';
import * as fromPolicyEditor from '../policy-editor/policy-editor-settings.reducer';

export interface State {
  app: fromApp.State;
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  fromAccounts: fromAccounts.State;
  fromChats: fromChats.State;
  userEntity: fromUserEntity.State;
  fromPolicyEditor: fromPolicyEditor.State;
}

export const reducers: ActionReducerMap<State> = {
  app: fromApp.reducer,
  router: fromRouter.routerReducer,
  fromAccounts: fromAccounts.reducer,
  fromChats: fromChats.reducer,
  userEntity: fromUserEntity.reducer,
  fromPolicyEditor: fromPolicyEditor.reducer,
};

/**
 * App error messages
 */

export const getAppErrorMessageState = (state: State) => state.app;

/**
 * User Entity Reducers
 */

export const getUserEntityState = (state: State) => state.userEntity;
export const getLoggedInUserInfo = createSelector(
  getUserEntityState,
  fromUserEntity.getLoggedInUserInfo
);

/**
 * fromChats Reducers
 */

export const getChatsState = (state: State) => state.fromChats;
export const getChats = createSelector(getChatsState, fromChats.getChats);
export const getChatTranscript = createSelector(
  getChatsState,
  fromChats.getChatTranscript
);
export const getChatSummary = createSelector(
  getChatsState,
  fromChats.getChatSummary
);
export const getChatsTotalCount = createSelector(
  getChatsState,
  fromChats.getTotalCount
);
export const getChatsOffset = createSelector(
  getChatsState,
  fromChats.getOffset
);
export const getMetadata = createSelector(getChatsState, fromChats.getMetadata);

/**
 * fromPolicyEditor Reducers
 */

export const getPolicyEditorState = (state: State) => state.fromPolicyEditor;
export const getPolicyEditorNotifications = createSelector(
  getPolicyEditorState,
  fromPolicyEditor.getPolicyEditorNotifications
);
export const getConfigSettings = createSelector(
  getPolicyEditorState,
  fromPolicyEditor.getConfigSettings
);
export const getPolicies = createSelector(
  getPolicyEditorState,
  fromPolicyEditor.getPolicies
);
export const getPolicySettings = createSelector(
  getPolicyEditorState,
  fromPolicyEditor.getPolicySettings
);

/**
 * Prod
 */
const productionReducer: ActionReducer<State> = combineReducers(reducers);
export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<State>>(
  'root reducer'
);

export function reducer(state: any, action: any) {
  return productionReducer(state, action);
}
