import { PaginatedData } from '@app/shared/interfaces/paginated-data.interface';
import {
  createAccount,
  getChatSessionById,
  loadAllChats,
  lookupChats,
  showMetadataTitle,
  resetMetadata,
} from './chats.actions';
import { Transcript } from '@app/shared/interfaces/transcript.interface';

export interface State {
  chats: Transcript[];
  chatTranscript: Transcript[];
  accounts: any;
  chatSummary: string;
  customer: any;
  newAccount: any;
  notification: any;
  modifiedAccount: any;
  metadata: any;
  totalCount: number;
  offset: number;
}

export const initialState: State = {
  chats: [],
  chatSummary: '',
  chatTranscript: [],
  accounts: {},
  metadata: {},
  customer: {},
  newAccount: {},
  notification: {},
  modifiedAccount: {},
  totalCount: 0,
  offset: 0,
};

// tslint:disable-next-line: typedef
export function reducer(state = initialState, action: any): State {
  let stateToReturn = state; // default

  switch (action.type) {
    case loadAllChats.success.type:
      return Object.assign({}, state, {
        chats: action.sessions,
        offset: action.offset,
        totalCount: action.total,
      });
    case lookupChats.success.type:
      return Object.assign({}, state, {
        chats: action.sessions,
      });
    case showMetadataTitle.type:
      return Object.assign({}, state, {
        metadata: action,
      });
    case resetMetadata.type:
      return Object.assign({}, state, {
        metadata: {},
      });
    case getChatSessionById.success.type:
      return Object.assign({}, state, {
        chatTranscript: action.transcript,
        chatSummary: action.summary,
      });
    case createAccount.success.type:
      return Object.assign({}, state, {
        newAccount: action.customer,
        notification: {
          type: 'CREATE_ACCOUNT_SUCCESS',
          data: {
            message: 'Account created Successfully',
          },
        },
      });
    case createAccount.success.type:
      return Object.assign({}, state, {
        newAccount: action.customer,
        notification: {
          type: 'CREATE_ACCOUNT_SUCCESS',
          data: {
            message: 'Account created Successfully',
          },
        },
      });
    default:
      stateToReturn = state;
      break;
  }
  return stateToReturn;
}

export const getTotalCount = (state: State) => state.totalCount;
export const getOffset = (state: State) => state.offset;
export const getChats = (state: State) => state.chats;
export const getChatTranscript = (state: State) => state.chatTranscript;
export const getChatSummary = (state: State) => state.chatSummary;
export const getMetadata = (state: State) => state.metadata;
