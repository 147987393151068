import { Component, OnInit } from '@angular/core';
import { finalize, skip, takeUntil } from 'rxjs/operators';
import {
  getAccountId,
  loadAllAccounts,
  lookupAccounts,
} from './history.actions';
import { Store } from '@ngrx/store';
import * as reducers from '../reducers';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Go } from '@app/router/router.actions';
import { SuccessSnackbarComponent } from '@app/shared/success-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaginatedListPayload } from '@app/shared/interfaces/paginated-list-payload.interface';
import { loadAllChats, lookupChats } from '@app/chats/chats.actions';
import { Transcript } from '@app/shared/interfaces/transcript.interface';
import { OnDestroy } from '@angular/core';
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit, OnDestroy {
  quote: string | undefined;
  isLoading = false;
  limit = 50;
  selection = new SelectionModel<any>(true, []);
  destroy$: Subject<boolean> = new Subject<boolean>();
  displayedColumns: string[] = [
    // 'checkbox',
    'date',
    'userid',
    'title',
    'summary',
  ];
  defaultColumns = [
    // { id: 'checkbox', name: 'Checkbox Selection', hidden: false },
    { id: 'date', name: 'Date', hidden: false },
    { id: 'userid', name: 'Student Email', hidden: false, disable: true },
    { id: 'title', name: 'Chat Title', hidden: false },
    { id: 'summary', name: 'Chat Summary', hidden: false },
  ];
  dataObject: PaginatedListPayload = {
    offset: 0,
    limit: this.limit,
    sortAscending: false,
    sortColumns: ['studentName'],
    allSessions: true,
  };
  columnNames: string[] = [
    // 'Checkbox Selection',
    'Date',
    'Student Email',
    'Chat Title',
    'Chat Summary',
  ];
  componentMap = {
    CREATE_ACCOUNT_SUCCESS: [SuccessSnackbarComponent, 'mat-snack-bar-success'],
    EDIT_ACCOUNT_SUCCESS: [SuccessSnackbarComponent, 'mat-snack-bar-success'],
  };
  accountsFilterForm: FormGroup;
  accounts: any = [];
  dataSource: any;
  currentPage = 0;
  totalCount = 0;
  chats: Transcript[] = [];
  pageSizeOptions = [25, 50, 100];
  constructor(
    private store: Store<reducers.State>,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {
    this.dataSource = [];
    this.accountsFilterForm = this.formBuilder.group({
      searchfilter: [],
    });
  }

  ngOnInit() {
    this.store.dispatch(loadAllChats.init({ payload: this.dataObject }));
    this.store
      .select(reducers.getChats)
      .pipe(takeUntil(this.destroy$))
      .subscribe((chats: any) => {
        this.chats = chats;
        this.dataSource = new MatTableDataSource(this.chats);
      });
    this.store
      .select(reducers.getChatsTotalCount)
      .pipe(takeUntil(this.destroy$))
      .subscribe((totalCount: number) => {
        this.totalCount = totalCount;
      });
    this.store
      .select(reducers.getChatsOffset)
      .pipe(takeUntil(this.destroy$))
      .subscribe((offset: number) => {
        this.currentPage = offset;
      });
  }
  sortData(sort: any) {
    this.dataObject = Object.assign({}, this.dataObject, {
      sort: [sort.active || 'primaryContactName'],
    });
    this.store.dispatch(loadAllAccounts.init({ payload: this.dataObject }));
  }

  getPageData() {
    return this.dataSource._pageData(
      this.dataSource._orderData(this.dataSource.filteredData)
    );
  }

  isEntirePageSelected() {
    return this.getPageData().every((row: any) =>
      this.selection.isSelected(row)
    );
  }

  masterToggle(checkboxChange: MatCheckboxChange) {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  onPageChange(event: any) {
    window.scrollTo(0, 0);
    this.currentPage = event.pageIndex;
    this.dataObject = Object.assign({}, this.dataObject, {
      limit: event.pageSize,
      offset: event.pageSize * event.pageIndex,
    });
    this.store.dispatch(loadAllChats.init({ payload: this.dataObject }));
  }

  getChatHistory(row: any) {
    this.store.dispatch(
      new Go({
        path: [`/history/details/${row.uuid}`],
      })
    );
  }
  onSearch(event: any) {
    this.dataObject = Object.assign({}, this.dataObject, {
      searchText: event?.target?.value || null,
    });
    this.store.dispatch(lookupChats.init({ payload: this.dataObject }));
  }
  goToAddAccount() {
    this.store.dispatch(
      new Go({
        path: ['/add'],
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
